import {lazy, FC, Suspense, useEffect, useState} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {MyPage} from '../pages/test'
import {permisoModulo} from '../../requests/moduleRequest'

const withPermissionCheck = (Component: FC, routePath: string) => {
  return () => {
    const [hasAccess, setHasAccess] = useState<boolean | null>(null);

    useEffect(() => {
      hasPermission(routePath).then((hasPermissionValue) => {
        setHasAccess(() => hasPermissionValue); 
      });
    }, []);

    if (hasAccess === null) {
      // Puede ser una mejor opción mostrar un componente de carga en lugar de null
      return null;
    } else if (!hasAccess) {
      return <Navigate to="/error/404" />;
    } else {
      return <Component />;
    }
  };
};

async function hasPermission(routePath: string) {
  const info = await permisoModulo(routePath);
  return info?.data?.hasPermission || false;
}



const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const RolePage = lazy(() => import('../modules/apps/role-management/RolePage'))
  const PedidoPage = lazy(() => import('../modules/apps/pedidos/PedidoPage'))
  const ClientesPage = lazy(() => import('../modules/apps/clientes/ClientesPage'))
  const ProductosPage = lazy(() => import('../modules/apps/productos/ProductosPage'))
  const ProductosClientesPage = lazy(() => import('../modules/apps/clientes-productos/clientesProductosPage'))
  const JaulasClientesPage = lazy(() => import('../modules/apps/jaula/JaulasPage'))
  const PlannerStockClientesPage = lazy(() => import('../modules/apps/planner/PlannerPage'))
  const CadenasPage = lazy(() => import('../modules/apps/cadenas/CadenasPage'))
  const TransportistasPage = lazy(() => import('../modules/apps/transportistas/TransportistasPage'))
  const DestinosPage = lazy(() => import('../modules/apps/destinos/DestinosPage'))
  const EmbarquesPage = lazy(() => import('../modules/apps/embarques/EmbarquesPage'))
  const UnidadesPage = lazy(() => import('../modules/apps/unidades/UnidadesPage'))
  const ClientesDireccionesPage = lazy(() => import('../modules/apps/clientes-direcciones/clientesDireccionesPage'))
  const DirectorioPage = lazy(() => import('../modules/apps/directorio/DirectorioPage'))
  const EmsPage = lazy(() => import('../modules/apps/ems/EmsPage'))
  const UnidadesPersonalPage = lazy (() => import('../modules/apps/unidades-personal/UnidadesPersonalPage'))
  const RutasPersonalPage = lazy (() => import('../modules/apps/rutas-personal/RutasPersonalPage'))
  const UsersManagerPage = lazy(() => import('../modules/apps/users/UsersPage'))
  const PizarraPage = lazy(() => import('../modules/apps/pizarra/PizarraPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route
          path="test"
          element={
            <SuspensedView>
              {withPermissionCheck(MyPage, 'test')()}
            </SuspensedView>
          }
        />
        
        <Route path='dashboard' element={<DashboardWrapper />} />
        
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
       
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <SuspensedView>
              {withPermissionCheck(AccountPage, '/crafted/account')()}
            </SuspensedView>
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              {withPermissionCheck(ProfilePage, 'crafted/pages/profile')()}
            </SuspensedView>
          }
        />
      
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              {withPermissionCheck(UsersPage, '/apps/user-management')()}
            </SuspensedView>
          }
        />
        <Route
          path='apps/role-management/*'
          element={
            <SuspensedView>
              {withPermissionCheck(RolePage, '/apps/role-management')()}
            </SuspensedView>
          }
        />
        <Route
          path='apps/pedidos/*'
          element={
            <SuspensedView>
              {withPermissionCheck(PedidoPage, '/apps/pedidos/listado')()}
            </SuspensedView>
          }
        />
        <Route
          path='apps/pedidos/*'
          element={
            <SuspensedView>
              {withPermissionCheck(PedidoPage, '/apps/pedidos/ordenes')()}
            </SuspensedView>
          }
        />
        <Route
            path='apps/cadenas/*'
            element={
              <SuspensedView>
                {withPermissionCheck(CadenasPage, '/apps/cadenas')()}
              </SuspensedView>
            }
          />
        <Route
          path='apps/transportistas/*'
          element={
            <SuspensedView>
              {withPermissionCheck(TransportistasPage, '/apps/transportistas')()}
            </SuspensedView>
          }
        />
          
        <Route
            path='apps/clientes/*'
            element={
              <SuspensedView>
                {withPermissionCheck(ClientesPage, '/apps/clientes')()}
              </SuspensedView>
            }
          />
        <Route
            path='apps/productos/*'
            element={
              <SuspensedView>
                {withPermissionCheck(ProductosPage, '/apps/productos')()}
              </SuspensedView>
            }
          />
          <Route
            path='apps/clientes-productos/*'
            element={
              <SuspensedView>
                {withPermissionCheck(ProductosClientesPage, '/apps/clientes-productos')()}
              </SuspensedView>
            }
          />
          <Route
            path='apps/jaula/*'
            element={
              <SuspensedView>
                {withPermissionCheck(JaulasClientesPage, '/apps/jaula')()}
              </SuspensedView>
            }
          />
          <Route
            path='apps/planner/*'
            element={
              <SuspensedView>
                {withPermissionCheck(PlannerStockClientesPage, '/apps/planner')()}
              </SuspensedView>
            }
          />
          <Route
            path='apps/destinos/*'
            element={
              <SuspensedView>
                <DestinosPage/>
              </SuspensedView>
            }
          />
          <Route
            path='apps/embarques/*'
            element={
              <SuspensedView>
                {withPermissionCheck(EmbarquesPage, '/apps/embarques')()}
              </SuspensedView>
            }
          />
          <Route
            path='apps/unidades/*'
            element={
              <SuspensedView>
                {withPermissionCheck(UnidadesPage, '/apps/unidades')()}
              </SuspensedView>
            }
          />
          <Route
            path='apps/unidades-personal/*'
            element={
              <SuspensedView>
                {withPermissionCheck(UnidadesPersonalPage, '/apps/unidades-personal')()}
              </SuspensedView>
            }
          />
          <Route
            path='apps/clientes-direcciones/*'
            element={
              <SuspensedView>
                {withPermissionCheck(ClientesDireccionesPage, '/apps/clientes-direcciones')()}
              </SuspensedView>
            }
          />
          
          <Route
            path='apps/rutas-personal/*'
            element={
              <SuspensedView>
                {withPermissionCheck(RutasPersonalPage, '/apps/rutas-personal')()}
              </SuspensedView>
            }
          />

          <Route
            path='apps/pizarra/*'
            element={
              <SuspensedView>
                {withPermissionCheck(PizarraPage, '/apps/pizarra')()}
              </SuspensedView>
            }
          />

        <Route
          path='apps/directorio/*'
          element={
            <SuspensedView>
              <DirectorioPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/ems/*'
          element={
            <SuspensedView>
              {withPermissionCheck(EmsPage, '/apps/ems')()}
            </SuspensedView>
          }
        />
        <Route 
          path='apps/users/*'
          element={
            <SuspensedView>
              {withPermissionCheck(UsersManagerPage, '/apps/users')()}
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
