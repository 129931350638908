const QUERIES = {
  USERS_LIST: 'users-list',
  ROLES_LIST: 'roles-list',
  PEDIDOS_LIST: 'pedidos-List',
  CLIENT_LIST: 'client-List',
  PRODUCT_LIST: 'products-List',
  PRODUCTOS_CLIENTE_LIST: 'products-clientes-list',
  PRODUCTOS_CLIENTE: 'products-cliente',
  JAULA_LIST:'jaula-list',
  PLANNER_LIST:'planner-list',
  PEDIDOS_CLIENTE_DESTINO_LIST:'pedidos_cliente_destino',
  APPOINTMENT_LIST: 'appointment-List',
  DETALLE_CITA:'appointment-detail',
  CADENA_LIST: 'cadenas-List',
  TRANSPORTISTAS_LIST: 'transportistas-List',
  DESTINOS_LIST: 'destinos-List',
  TARIFAS_LIST: 'tarifas-List',
  TARIFA: 'tarifa',
  DESTINOS_LIST_ALL: 'destinos-List-all',
  AUTORIZACIONES_LIST: 'autorizaciones-List',
  COSTODESTINO: 'costo-destino',
  TARIFAS_COMPARATIVO: 'tarifas-comparativo',
  EMBARQUES_LIST: 'embarques-List',
  DETALLE_CITA_EMBARQUE: 'detalle-cita-embarque',
  CITA_EMBARQUE: 'cita-embarque',
  UNIDADES_LIST: 'unidades-List',
  UNIDADES_LIST_PERSONAL: 'unidades-personal-List',
  CUBICAJE_LIST: 'cubicaje-List',
  DIRECCIONES_CLIENTES_LIST: 'direcciones-clientes-list',
  BITACORA_CITA_LISTA: 'bitacora-cita-List',
  REGISTROS_EVIDENCIAS_LIST: 'registros-evidencias-List',
  EVIDENCIA_LIST: 'evidencia-list',
  EVIDENCIA_QUBICAJE: 'evidencia-cubicaje-id',
  EVIDENCIA_DESGLOSE: 'evidencia-desglose-id',
  RUTAS_PERSONAL_LIST: 'rutas-personal-list',
  PIZARRA: 'pizarra',
}

export {QUERIES}
